<template>
  <b-card>

    <b-modal
      id="franchise-revenue-modal"
      :title="`${updateId ? 'Update' : 'Add'} Franchise Fee`"
      size="lg"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleFranchiseRevenueFormSubmit"
      @cancel="resetFranchiseRevenue"
      @close="resetFranchiseRevenue"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Session"
              label-cols-md="4"
              :state="sessionDateState"
              invalid-feedback="Both Session Date is Required"
            >
              <div class="d-flex align-items-center">
                <!-- From Session Date -->
                <b-form-datepicker
                  id="voucher_start_date"
                  v-model="franchiseRevenueFormData.fromSessionDate"
                  class="mr-1"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="fromSessionDateState"
                />
                <!-- To Session Date -->
                <label class="mr-1">To</label>
                <b-form-datepicker
                  id="voucher_end_date"
                  v-model="franchiseRevenueFormData.toSessionDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="toSessionDateState"
                />
              </div>
            </b-form-group>
          </b-col>
          <!-- Franchisor Campus -->
          <b-col cols="12">
            <b-form-group
              label="Franchisor Campus"
              label-cols-md="4"
              :state="franchisor_campusState"
              invalid-feedback="Franchisor Campus is Required"
            >
              <v-select
                v-model="franchiseRevenueFormData.franchisor_campus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Franchisor Campus"
                :options="campusOptions"
                :reduce="val => val.id"
                :state="franchisor_campusState"
              />
            </b-form-group>
          </b-col>
          <!-- Franchisee Campus -->
          <b-col cols="12">
            <b-form-group
              label="Franchisee Campus"
              label-cols-md="4"
              :state="campusIdState"
              invalid-feedback="Franchisee Campus is Required"
            >
              <v-select
                v-model="franchiseRevenueFormData.campusId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Franchisee Campus"
                :options="campusOptions"
                :reduce="val => val.id"
                :state="campusIdState"
              />
            </b-form-group>
          </b-col>
          <!-- Class -->
          <b-col cols="12">
            <b-form-group
              label="Class"
              label-cols-md="4"
              :state="classIdState"
              invalid-feedback="Class is Required"
            >
              <v-select
                v-model="franchiseRevenueFormData.classId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Class"
                :options="classOptions"
                :reduce="val => val.id"
                :state="classIdState"
              />
            </b-form-group>
          </b-col>
          <!-- Percentage -->
          <b-col cols="12">
            <b-form-group
              label="Percentage"
              label-for="amount"
              label-cols-md="4"
              :state="percentageState"
              invalid-feedback="Percentage is Required"
            >
              <b-form-input
                v-model="franchiseRevenueFormData.percentage"
                type="number"
                placeholder="Enter Percentage"
                :state="percentageState"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Franchise Revenue
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.franchise-revenue-modal
          variant="outline-primary"
          style="text-transform: capitalize"
        >
          Add Franchise Revenue
        </b-button>
      </b-form-group>

    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect, BPagination, BButton, BFormDatepicker, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    BFormDatepicker,
    RefreshCwIcon,
    BLink,
    ActivityModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Campus',
          field: 'campus.name',
        },
        {
          label: 'Class',
          field: 'academic_class.name',
        },
        {
          label: 'From Session',
          field: 'session_from_date',
        },
        {
          label: 'To Session',
          field: 'session_to_date',
        },
        {
          label: 'Percentage',
          field: 'percentage',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      franchiseRevenueFormData: {},
      campusOptions: [],
      classOptions: [],

      fromSessionDateState: null,
      toSessionDateState: null,

      sessionDateState: null,

      franchisor_campusState: null,
      campusIdState: null,
      classIdState: null,
      percentageState: null,

      tag: 'franchise-revenue',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getCampus()
    this.getClass()
    this.getFranchiseRevenueConfigGridData()
  },
  methods: {
    getCampus() {
      const authToken = localStorage.getItem('accessToken')
      this.$http
        .get(`${window.apiUrl}accounting/franchise-revenue-config/campus`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then(res => {
          if (res.data.status) {
            this.campusOptions = res.data.data
          } else {
            this.campusOptions = []
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getClass() {
      const authToken = localStorage.getItem('accessToken')

      this.$http
        .get(`${window.apiUrl}accounting/franchise-revenue-config/class`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then(res => {
          if (res.data.status) {
            this.classOptions = res.data.data
          } else {
            this.classOptions = []
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    handleFranchiseRevenueFormSubmit() {
      if (this.updateId) {
        this.updateFranchiseRevenue()
      } else {
        this.saveFranchiseRevenue()
      }
    },
    validate() {
      this.fromSessionDateState = null
      this.toSessionDateState = null
      this.sessionDateState = null
      this.franchisor_campusState = null
      this.campusIdState = null
      this.classIdState = null
      this.percentageState = null
      if (!this.franchiseRevenueFormData.fromSessionDate && !this.franchiseRevenueFormData.toSessionDate) {
        this.fromSessionDateState = false
        this.toSessionDateState = false
        this.sessionDateState = false
      }
      if (!this.franchiseRevenueFormData.franchisor_campus || this.franchiseRevenueFormData.franchisor_campus === null || this.franchiseRevenueFormData.franchisor_campus === '') {
        this.franchisor_campusState = false
      }
      if (!this.franchiseRevenueFormData.campusId || this.franchiseRevenueFormData.campusId === null || this.franchiseRevenueFormData.campusId === '') {
        this.campusIdState = false
      }
      if (!this.franchiseRevenueFormData.classId || this.franchiseRevenueFormData.classId === null || this.franchiseRevenueFormData.classId === '') {
        this.classIdState = false
      }
      if (!this.franchiseRevenueFormData.percentage || this.franchiseRevenueFormData.percentage === null || this.franchiseRevenueFormData.percentage === '') {
        this.percentageState = false
      }

      if (this.sessionDateState !== false && this.campusIdState !== false && this.campusIdState !== false
        && this.classIdState !== false && this.percentageState !== false) {
        return true
      }
      return false
    },
    saveFranchiseRevenue() {
      if (!this.validate()) {
        return
      }
      const authToken = localStorage.getItem('accessToken')
      this.$http
        .post(
          `${window.apiUrl}accounting/franchise-revenue-config`,
          this.franchiseRevenueFormData, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        .then(res => {
          if (res.data.status) {
            this.franchiseRevenueFormData = {}
            this.getFranchiseRevenueConfigGridData()
            FemsToastrService.success(res.data.message)
            this.$bvModal.hide('franchise-revenue-modal')
          } else {
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    updateFranchiseRevenue() {
      if (!this.validate()) {
        return
      }
      this.$http
        .put(
          `${window.apiUrl}accounting/franchise-revenue-config/${this.updateId}`,
          this.franchiseRevenueFormData,
        )
        .then(res => {
          if (res.data.status) {
            this.franchiseRevenueFormData = {}
            this.getFranchiseRevenueConfigGridData()
            FemsToastrService.success(res.data.message)
            this.$bvModal.hide('franchise-revenue-modal')
          } else {
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getFranchiseRevenueConfigGridData() {
      const authToken = localStorage.getItem('accessToken')
      this.$http
        .get(`${window.apiUrl}accounting/franchise-revenue-config`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then(res => {
          if (res.data.status) {
            this.rows = res.data.data
          } else {
            this.rows = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    refreshGrid() {
      this.getFranchiseRevenueConfigGridData()
    },
    getFranchiseRevenueDebitCoa() {
      this.$http
        .get(`${window.apiUrl}accounting/debit-voucher/debit-coa`)
        .then(res => {
          if (res.data.status) {
            this.expenseOptions = res.data.data
          } else {
            this.expenseOptions = []
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getFranchiseRevenueCreditCoa() {
      this.$http
        .get(`${window.apiUrl}accounting/debit-voucher/credit-coa`)
        .then(res => {
          if (res.data.status) {
            this.paymentModeOptions = res.data.data
          } else {
            this.paymentModeOptions = []
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    resetFranchiseRevenue() {
      this.updateId = null
      this.franchiseRevenueFormData = {}
      this.fromSessionDateState = null
      this.toSessionDateState = null
      this.sessionDateState = null
      this.franchisor_campusState = null
      this.campusIdState = null
      this.classIdState = null
      this.percentageState = null
    },
    editRow(rowData) {
      this.updateId = rowData.id
      this.franchiseRevenueFormData = {
        fromSessionDate: rowData.session_from_date,
        toSessionDate: rowData.session_to_date,
        campusId: rowData.campus_id,
        classId: rowData.class_id,
        percentage: rowData.percentage,
      }

      this.$bvModal.show('franchise-revenue-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`${window.apiUrl}accounting/franchise-revenue-config/${rowData.id}`)
            .then(res => {
              if (res.status && res.data.status) {
                this.franchiseRevenueFormData = {}
                this.getFranchiseRevenueConfigGridData()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: res.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                FemsToastrService.error(res.data.message)
              }
            })
        }
      })
    },

    // Audit ACtivity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
